import React from 'react'
import Header from '../components/Header'

const About = () => {
  return (
    <div className='bg-secondary-300 pb-24'>
      <Header home={false}/>
      <div className="relative">
        <img className=" w-full h-2/6 md:h-72 object-fit" src="/assets/about-hero1.jpg" alt=""/>
          <h1 className="absolute text-3xl md:text-6xl text-white-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">About Us</h1>
      </div>
      <section className="h-auto flex items-center justify-center md:my-24">
        <div className="flex flex-col-reverse md:flex-row justify-center items-center h-full w-10/12">
          <div className="relative  md:w-[40%] h-auto md:ml-11">
            <img src="/assets/about-pba.png" alt=""/>
            <img className="absolute top-3/4 left-7 translate-y-1/4" src="/assets/make-a-diffrence-badge.png" alt=""/>
              </div>
              <div className=" h-auto w-full md:w-[50%] my-16">
                <h2 className="text-3xl text-center md:text-left md:text-5xl font-bold mb-6">A place-based approach</h2>
            <p className="text-xl md:2xl text-center md:text-left">Through all these, the organization reached out over 15,000 marginalized and vulnerable people mainly children, youths and women in its operational areas.</p>
                <div className="w-full bg-yellow-200 p-2 rounded-md flex justify-center items-center my-6">
              <img src="/assets/affection-logo.png" alt=""/>
              <p className="text-xl pl-4">Through all these, the organization reached out over 15,000 marginalized and vulnerable people mainly children, youths and women in its operational areas.   </p>

                </div>
                <div className="flex flex-col md:flex-row justify-start items-center">
                  <div className="md:w-[30%] w-full p-6 bg-[#00956F] h-full mb-5 md:mr-5 rounded-md">
                    <h2 className="text-white-100 text-5xl text-center">124mil</h2>
                    <h2 className="text-white-100 text-2xl text-center">Dollars we collected</h2>
                  </div>
              <p className="text-xl md:w-[50%] w-full">SID Ethiopia has comparative advantage in implementing both emergency and development projects and programs in Addis Ababa and Dire Dawa administrations as well as Harari region. </p>
                </div>
              </div>
          </div>
      </section>
      <section className="bg-red flex justify-center  w-full h-auto mt-60 md:mt-52">
        <div className="flex flex-col md:flex-row items-start  justify-center w-10/12">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className="border-l-[3px] border-green-400 pl-3 md:mb-5 mb-7">
              <h2 className="text-3xl md:text-5xl font-bold mb-6">Our Vision</h2>
              <p className="text-xl w-full mb-6">To see self-reliance and vibrant generation in Ethiopia </p>
              <p className="text-xl w-full ">The organization recognizes its easy access to the grass roots level of the community.</p>
            </div>
            <div className="flex flex-col md:flex-row justify-start items-center">
              <div className="text-white-100 md:w-[30%] w-full p-6 bg-[#00956F] h-full mb-5 md:mr-5 rounded-md">
                <h2 className="text-5xl text-center">15Y</h2>
                <h2 className=" text-2xl text-center">Years of experience</h2>
              </div>
              <p className="text-xl md:w-[70%] w-full">Beside SID Ethiopia is working in collaboration with any change agent that  include local expertise and resources, volunteers, CSOs, the UN agencies, donors, international organizations, well-wishers and charities </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 border-l-[3px] border-green-400 pl-3">
            <h2 className="text-3xl md:text-5xl font-bold mb-2">About Us</h2>
            <h2 className="text-2xl md:text-3xl text-[#00956F] mb-6">Help is Our Main Goal</h2>
            <p className="text-xl w-full mb-6">Stand for Integrated Development Ethiopia (SID Ethiopia) aims and stands to improve the wellbeing of vulnerable and marginalized youths, women, children and adults. It has operational presence and has been implementing programs with high impacts and remarkable achievements in Harari, Somali and Oromia regions as well as Addis Ababa and Dire Dawa city administrations.</p>

          </div>
        </div>
      </section>
      <section className="mt-20 flex justify-center items-start ">
        <div className="flex flex-wrap justify-center md:justify-between items-center w-10/12">
          <div className="mr-8 md:mr-0 flex flex-col md:flex-row justify-start items-center">
            <div className="py-4 px-10 text-black bg-[#EED284] h-full mb-5 md:mr-5 rounded-md">
              <h2 className="text-5xl text-left">3<span className="text-lg">+</span></h2>
              <h2 className="text-lg text-left">Regions operating</h2>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-start items-center">
            <div className="py-4 px-10 text-black bg-[#FCC531] h-full mb-5 md:mr-5 rounded-md">
              <h2 className="text-5xl text-left">31mil<span className="text-lg">+</span></h2>
              <h2 className="text-lg text-left">Managed in 3 years</h2>
            </div>
          </div>

          <div className="mr-4 md:mr-0 flex flex-col md:flex-row justify-start items-center">
            <div className="py-4 px-10 text-black bg-[#66B996] h-full mb-5 md:mr-5 rounded-md">
              <h2 className="text-5xl text-left">5<span className="text-lg">+</span></h2>
              <h2 className="text-lg text-left">Rehabilitation Center</h2>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-start items-center">
            <div className="py-4 px-10 text-black bg-[#CAB372] h-full mb-5 md:mr-5 rounded-md">
              <h2 className="text-5xl text-left">850<span className="text-lg">+</span></h2>
              <h2 className="text-lg text-left">Homless helped at the centers</h2>
            </div>
          </div>


        </div>
      </section>
    </div>
  )
}

export default About