import {useState} from 'react'
import Home from './pages/Home.jsx'
import Contact from './pages/Contact.jsx'
import {Route, BrowserRouter, Routes} from 'react-router-dom'
import Blog from "./pages/Blog.jsx";
import BlogDetail from "./pages/BlogDetail.jsx";
import About from "./pages/About.jsx";
import Header from "./components/Header.jsx";
import WhatWeDo from "./pages/WhatWeDo";
import Footer from './components/Footer.jsx';
function App() {


  return (
      <div className='overflow-x-hidden sm:overflow-x-visible'>
        <BrowserRouter>
          {/*<Header/>*/}
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/contact-us' element={<Contact/>}/>
            <Route path='/about-us' element={<About/>}/>
            <Route path='/what-we-do' element={<WhatWeDo/>}/>
            <Route path='/blog' element={<Blog/>}/>
            <Route path='/blog/:slug' element={<BlogDetail/>}/>
          </Routes>
          <Footer/>
        </BrowserRouter>

      </div>
  )
}

export default App
