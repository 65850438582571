import Header from '../components/Header'
import {useSubscription} from "@apollo/client";
import { useParams } from 'react-router';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

const QUERY = gql`
    query getPost($slug: String!){
        post(where: {slug: $slug}){
            title
            description
            content {
                html
            }
            datePublished
            slug
            author{
                name
                biography
               
            }
            coverPhoto{
                url
            }      
           
        }
    }
    
`

const BlogDetail = () => {

    const { slug } = useParams();
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(QUERY, {
        variables: { slug },
    });


    if (loading) return  <div className='h-[100vh] bg-secondary-300 flex justify-center items-center'><ReactLoading type='bubbles' color='#006E52' height={400} width={300} /></div>
    if (error) return <pre>{error.message}</pre>

    return (
        <div>
            <Header home={false}/>
            <div className='sm:block md:flex min-h-[90vh] justify-around sm:pl-4 sm:pr-0 md:px-32 pt-16 bg-secondary-300'>
                <div className='sm:w-[100%] md:w-[70%] flex flex-col gap-5'>
                    <h1 className='text-4xl font-medium text-primary-100'>{data.post.title}</h1>
                    <p className='text-md text-black font-medium'>{data.post.datePublished}</p>
                    <img className='w-[80%]' src={data.post.coverPhoto.url}/>
                    <p className='text-lg sm:w-[100%] md:w-[80%] font-medium' dangerouslySetInnerHTML={{__html: data.post.content.html}}></p>
                    <p className='text-lg text-primary-100 font-medium'>Article by: {data.post.author.name}</p>
                </div>
                <div className='sm:w-[50%] md:w-[23%] h-[50vh] rounded-xl shadow-xl'>
                    <div className='w-full h-[85%] bg-secondary-400 rounded-xl shadow-xl p-7'>
                        <h1 className='text-primary-200 text-center text-2xl'>Facebook Updates</h1>
                    </div>
                    <a href="https://www.facebook.com/groups/789578295324460/?ref=share&mibextid=KtfwRi" target='_blank'><h1 className='text-primary-200 text-xl font-semibold text-right mt-5 mr-3 underline cursor-pointer'>Follow us on Facebook</h1></a>
                    
                </div>
            </div>
        </div>
    )
}

export default BlogDetail