import {AiOutlineArrowRight} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

const Card = ({title, image, description, date, slug}) => {
    const router = useNavigate()

    const blockClickHandler = ()=>{
        console.log("mehhhhhhh")
        router(`/blog/${slug}`)
    }
    return (
        <div className='w-full sm:w-[40%]'>
            <p className='text-white-200 text-sm font-semibold'>{date}</p>
            <div className='mt-2 flex flex-col justify-around gap-5'>

                <img className=' w-full h-[300px] object-cover rounded-xl' src={image}/>
                <h1 className='text-2xl font-bold text-primary-100'>{title}</h1>
                <p className='text-md font-medium'>{description}</p>
                <div onClick={blockClickHandler} className='flex justify-start gap-1 items-center underline'>
                    <button className="text-xl text-[#006395] font-semibold">Read More</button>
                    <AiOutlineArrowRight className='mt-1 text-xl font-bold text-[#006395]'/>
                </div>
            </div>
        </div>
    )
}

export default Card