const Card = ({icon, title, desc}) => {
    return (
        <div className='my-10 pb-12 w-full sm:w-[30%] h-auto sm:h-[53vh] bg-[#66B996] p-10 rounded-xl flex flex-col justify-start gap-5'>

            <img className='w-[20%] font-medium relative -top-20' src={icon}/>
            <div className='-mt-20 '>
                <h1 className='text-3xl font-medium'>{title}</h1>
                <p className='text-lg font-medium mt-5 pb-14'>{desc}</p>
            </div>
        </div>
    )
}

export default Card