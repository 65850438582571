const Hero = () => {
    return (
        <div className='p-10 sm:px-28 sm:h-[90vh] bg-primary-200 '>
            <div className=' h-full flex flex-col-reverse sm:flex-row justify-center items-center gap-10'>

                <div className='border-[red] w-full sm:w-[40%] flex flex-col justify-center gap-10 items-start'>
                    <h1 className='text-5xl text-secondary-100'>
                        We need your support now, more than ever.
                    </h1>
                    <p  className='text-white-100 text-xl'>
                        Help make a real difference in other people’s lives by making a donation today. Your generous
                        contribution can help us continue our work so that we can improve the quality of life for
                        others.
                    </p>
                    <button className='transition-all duration-200 ease-in bg-secondary-100 px-10 py-2 text-white-100 text-xl rounded-xl hover:bg-secondary-200'>
                        <a href="/what-we-do">Lean how you can help</a> 
                    </button>
                </div>
                <img className='relative z-0 -bottom-4 h-full sm:w-[55%]' src='/assets/hero-image1.jpg'/>
            </div>
        </div>
    )
}

export default Hero