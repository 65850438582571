import React, {useState} from 'react'
import Header from '../components/Header'

const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    return (
        <>
            <Header home={false}/>

            <div className='bg-secondary-300 pb-[110px]'>
                <div className='h-full pt-11 flex justify-center item-center'>
                    <div
                        className="bg-white-100 w-4/5  rounded-xl m-auto flex flex-col md:flex-row justify-center items-center ">
                        <div className="h-auto w-full md:1/2 rounded-l-xl">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d246.27710894084885!2d38.82590936983111!3d9.024137887658878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snl!4v1681269012197!5m2!1sen!2snl"
                                style={{border: "0"}} className=" md:ml-3 rounded-md w-full h-64 md:h-[500px]"
                                allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="w-full md:1/2 h-auto rounded-r-xl flex flex-col justify-center items-center p-10">
                            <h2 className="text-center text-4xl font-semibold  mb-9 text-green-600">Contact Us</h2>
                            <form className="w-full flex flex-col justify-between" action="">
                                <input value={name} onChange={e => setName(e.target.value)}
                                       className="indent-3  outline-none rounded-xl bg-gray-100 pl-2 h-10 border-none mb-4 text-base text-gray-900"
                                       placeholder="Name" type="text" name="name"/>
                                <input value={email} onChange={e => setEmail(e.target.value)} placeholder="Email"
                                       className="placeholder-gray-400  indent-3 outline-none rounded-xl bg-gray-100 pl-2 h-10 border-none mb-4 text-base"
                                       type="text" name="email"/>
                                <textarea value={message} onChange={e => setMessage(e.target.value)}
                                          placeholder="Message"
                                          className="indent-3 outline-none rounded-xl bg-gray-100 pl-2 border-none mb-4 text-base"
                                          name="" id="" cols="30" rows="8"></textarea>
                                <input
                                    className="indent-3 outline-none rounded-xl bg-green-600 pl-2 h-11 cursor-pointer text-white-100 text-xl"
                                    type="submit"/>
                            </form>
                            <div className='pt-4 flex flex-wrap gap-x-8 gap-y-2 text-green-600'>
                                <a href="tel:+251-116-68-31-88"><h3 className=' cursor-pointer'>Telephone: +251-116-68-31-88 </h3></a>
                                <a href="mailto: sideethiopia@yahoo.com"><h3 className=' cursor-pointer'>Email: sideethiopia@yahoo.com </h3></a>
                                <h3 className=' cursor-pointer'>P.O. Box: 12115, Addis Ababa</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='mt-40 flex flex-col justify-center items-center'>
                    <h2 className="text-center text-5xl font-semibold  mb-9 ">Contact Team</h2>
                    <div className='w-4/5 flex flex-wrap justify-between my-0 mx-auto'>
                        <div className='w-[300px] pb-8 px-2 pt-2 bg-white-100 rounded-md mb-5'>
                            <img src="/assets/selam.jpg" alt="" />
                            <h3 className='text-xl font-semibold text-center mt-3'>Executive Director <br /><span className='font-normal'>Selamyehun Aklilu</span> </h3>
                            <h3 className='text-md text-center mt-3 text-primary-200'><a href="mailto: selamyehun@sidethiopia.com"><h3 className=' cursor-pointer'>selamyehun@sidethiopia.com</h3></a></h3>
                        </div>
                        <div className='w-[300px] pb-8 px-2 pt-2 bg-white-100 rounded-md mb-5'>
                            <img src="/assets/solomon.jpg" alt="" />
                            <h3 className='text-xl font-semibold text-center mt-3'>Program Manager <br /><span className='font-normal'>Solomon Workneh</span> </h3>
                            <h3 className='text-md text-center mt-3 text-primary-200'><a href="mailto: solomon@sidethiopia.com"><h3 className=' cursor-pointer'>solomon@sidethiopia.com</h3></a></h3>
                        </div><div className='w-[300px] pb-8 px-2 pt-2 bg-white-100 rounded-md mb-5'>
                            <img src="/assets/surafel.jpg" alt="" />
                            <h3 className='text-xl font-semibold text-center mt-3'>Admin and Finance Manager <br /><span className='font-normal'>Surafel Mezgebu</span> </h3>
                            <h3 className='text-md text-center mt-3 text-primary-200'><a href="mailto: surafel@sidethiopia.com	"><h3 className=' cursor-pointer'>surafel@sidethiopia.com</h3></a></h3>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default Contact