import Header from "../components/Header.jsx";
import Card from '../components/Blog/Card.jsx'
import {useQuery, gql} from "@apollo/client";
import {useEffect, useState} from "react";
import ReactLoading from 'react-loading';
import {FiChevronLeft} from 'react-icons/fi'
import {AiOutlineRight} from 'react-icons/ai'

const GET_POSTS_BY_CATEGORY = gql`
  query GetPostsByCategory($categoryName: String!) {
    posts(where: { categories: { name: $categoryName } }) {
      title
      description
      datePublished
      content {
        html
      }
      slug
      author {
        name
      }
      coverPhoto {
        url
      }
      categories {
        name
      }
    }
  }
`;

const GET_CATEGORIES = gql`
    {
  categoriesS(first:500){
    name
  }
}

`

const GET_ALL_POSTS = gql`
  query GetAllPosts {
    posts(first:500) {
      title
      description
      datePublished
      content {
        html
      }
      slug
      author {
        name
      }
      coverPhoto {
        url
      }
      categories {
        name
      }
    }
  }
`;


const Blog = () => {

    const [categoryName, setCategoryName] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(6); // Number of posts to show per page

    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categoriesData,
    } = useQuery(GET_CATEGORIES);

    const {
        loading,
        error,
        data: allPostsData,
    } = useQuery(GET_ALL_POSTS);

    const {
        loading: categoryPostsLoading,
        error: categoryPostsError,
        data: categoryPostsData,
    } = useQuery(GET_POSTS_BY_CATEGORY, {
        variables: { categoryName },
    });

    if (loading || categoryPostsLoading || categoriesLoading)
        return (
            <div className="h-[100vh] bg-secondary-300 flex justify-center items-center">
                <ReactLoading type="bubbles" color="#006E52" height={400} width={300} />
            </div>
        );

    const posts = categoryName
        ? categoryPostsData?.posts
        : allPostsData?.posts;


    
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts && [...posts].reverse().slice(indexOfFirstPost, indexOfLastPost);


    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(posts && posts.length / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const filterOnClickHandler = (category) => {
        setCategoryName(category);
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    if (error) return <pre>{error.message}</pre>;
    if (categoriesError) return <pre>{categoriesError.message}</pre>;


    return (
        <div className='bg-secondary-300 pb-10'>
            <Header home={false}/>
            <div className='p-16 flex flex-col sm:flex-row justify-between min-h-[90vh] gap-20'>
                <div className='w-[20%]'>
                    <h1 className='uppercase text-2xl font-bold text-primary-100 border-b-[3px] border-primary-100 '>Catagories</h1>
                    <div className='mt-5'>
                        {
                            categoriesData?.categoriesS.map((item) => (
                                <button onClick={()=>filterOnClickHandler(item.name)} className='my-1 underline block text-xl font-semibold'
                                        key={item.id}>
                                    {item.name}
                                </button>
                            ))
                        }
                    </div>
                </div>
                <div className='w-full sm:w-[75%] flex justify-around flex-wrap w-full gap-20'>
                    {
                        currentPosts.map((item,i) => (
                            <Card key={i} slug={item.slug} title={item.title} description={item.description} image={item.coverPhoto.url}
                                  date={item.datePublished}/>
                        ))
                    }
                </div>

            </div>
            <div className="h-[5vh] flex gap-3 justify-center items-center">
                {/*{ &&}*/}
                <button className={` px-3 py-2 rounded-md ${currentPage > 1 ? 'bg-white-100' : 'bg-gray-400 text-white-100'}`} onClick={prevPage}><FiChevronLeft /></button>
                {pageNumbers.map(number => (
                    <button key={number} onClick={() => paginate(number)} className={`bg-white-100 px-4 py-1 font-semibold rounded-md ${currentPage === number ? 'text-primary-200 border-[1px] border-primary-200' : ''}  `}>
                        {number}
                    </button>
                ))}
                <button className={` px-3 py-2 rounded-md ${currentPage < Math.ceil(posts.length / postsPerPage) ? 'bg-white-100' : 'bg-gray-400 text-white-100'}`} onClick={nextPage}><AiOutlineRight /></button>
            </div>
        </div>
    )
}

export default Blog