import React from 'react'

function Footer() {
  return (
    <div className='pt-8 pb-6 bg-[#fff6c4] grid place-items-center'>
      <div className='w-2/3 text-xs  text-center content-center'><p className=' '>STAND FOR INTEGRATED DEVELOPMENT ETHIOPIA is an independent, impartial and neutral NGO that established 2000 EC. It has been registered and accorded legal personality with Registry Number 1551 on August 02, 2019 G.C as Local Organization in accordance with the Civil Society Organizations Proclamation No. 1113/2019.</p>

        <p>  © Copyright 2023. All Rights Reserved. Designed and Developed by <span className='text-blue'> Adey Digtal</span>.</p></div>
    </div>
     
  )
}

export default Footer