import Header from "../components/Header.jsx";
import Hero from "../components/Home/Hero.jsx";
import Card from "../components/Home/Card.jsx";
import { useQuery, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import { FiChevronLeft } from 'react-icons/fi'
import { AiOutlineRight } from 'react-icons/ai'

const GET_POSTS_BY_CATEGORY = gql`
  query GetPostsByCategory($categoryName: String!) {
    posts(where: { categories: { name: $categoryName } }) {
      title
      description
      datePublished
      content {
        html
      }
      slug
      author {
        name
      }
      coverPhoto {
        url
      }
      categories {
        name
      }
    }
  }
`;

const GET_CATEGORIES = gql`
    {
  categoriesS{
    name
  }
}

`

const GET_ALL_POSTS = gql`
  query GetAllPosts {
    posts(first:500) {
      title
      description
      datePublished
      content {
        html
      }
      slug
      author {
        name
      }
      coverPhoto {
        url
      }
      categories {
        name
      }
    }
  }
`;



const Home = () => {

    const articles = [
        {
            id: 1,
            title: 'Promoting Food Security in Rural Ethiopia',
            image: '/assets/article1.svg',
        },
        {
            id: 2,
            title: 'Promoting Food Security in Rural Ethiopia',
            image: '/assets/article2.svg',
        },
        {
            id: 3,
            title: 'Promoting Food Security in Rural Ethiopia',
            image: '/assets/article1.svg',
        },
        {
            id: 1,
            title: 'Promoting Food Security in Rural Ethiopia',
            image: '/assets/article1.svg',
        },
        {
            id: 2,
            title: 'Promoting Food Security in Rural Ethiopia',
            image: '/assets/article2.svg',
        },
        {
            id: 3,
            title: 'Promoting Food Security in Rural Ethiopia',
            image: '/assets/article1.svg',
        },
    ]

    const [categoryName, setCategoryName] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(6); // Number of posts to show per page

    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categoriesData,
    } = useQuery(GET_CATEGORIES);

    const {
        loading,
        error,
        data: allPostsData,
    } = useQuery(GET_ALL_POSTS);

    const {
        loading: categoryPostsLoading,
        error: categoryPostsError,
        data: categoryPostsData,
    } = useQuery(GET_POSTS_BY_CATEGORY, {
        variables: { categoryName },
    });

    if (loading || categoryPostsLoading || categoriesLoading)
        return (
            <div className="h-[100vh] bg-secondary-300 flex justify-center items-center">
                <ReactLoading type="bubbles" color="#006E52" height={400} width={300} />
            </div>
        );

    const posts = categoryName
        ? categoryPostsData?.posts
        : allPostsData?.posts;



    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - 6;
    const currentPosts = posts && [...posts].reverse().slice(indexOfFirstPost, indexOfLastPost);;
    



    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(posts && posts.length / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const filterOnClickHandler = (category) => {
        setCategoryName(category);
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    if (error) return <pre>{error.message}</pre>;
    if (categoriesError) return <pre>{categoriesError.message}</pre>;

    return (
        <div className='bg-secondary-300 pb-24'>
            <Header home={true}/>
            <Hero/>
            {/*<img className='w-full' src='/wave-haikei2.svg'/>*/}
            <div className='relative w-full pb-full overflow-hidden inline-block align-middle'>

                <svg id="visual" viewBox="0 0 540 30" className='w-full z-1000000' xmlns="http://www.w3.org/2000/svg"
                      version="1.1">
                    <rect x="0" y="0" width="540" height="30" fill="#F3EDCD"></rect>
                    <path
                        d="M0 14L18 14.8C36 15.7 72 17.3 108 17.2C144 17 180 15 216 13C252 11 288 9 324 8C360 7 396 7 432 8.2C468 9.3 504 11.7 522 12.8L540 14L540 0L522 0C504 0 468 0 432 0C396 0 360 0 324 0C288 0 252 0 216 0C180 0 144 0 108 0C72 0 36 0 18 0L0 0Z"
                        fill="#006E52" stroke-linecap="round" stroke-linejoin="miter"></path>
                </svg>


                <div className=' px-10 sm:px-32 mt-10 sm:mt-32 flex flex-col gap-20'>
                    {/*<img  src='../../public/assets/curve.svg'/>*/}
                    <div className='flex justify-between flex-col-reverse sm:flex-row'>
                        <div className='w-full sm:w-1/2 flex justify-start flex-col gap-5'>
                            <h1 className='text-4xl text-primary-100 font-medium leading-tight'>
                                We are dedicated
                                to working with communities around the world
                            </h1>
                            <p className='text-xl text-secondary-200 '>Strive to develop projects which will have a
                                lasting
                                impact and create meaningful change. Whether it’s helping women in developing countries
                                access
                                education</p>
                            <p className='text-lg text-primary-100 font-medium'>Our organization is independent,
                                impartial
                                and
                                neutral. We put at the center social justice, gender equality, human rights,
                                international
                                peace, and sustainable development outcomes for local communities in rural and urban
                                areas
                                across Ethiopia. our mission is to make the most impact for the most vulnerable by
                                ensuring
                                that
                                everyone has access to education, healthcare and a safe place to live </p>

                        </div>
                        <img className='h-[28rem]' src='/assets/child1.svg'/>
                    </div>

                    <div className=''>
                        <h1 className='text-primary-100 text-3xl sm:text-4xl text-center font-semibold my-10'>Latest
                            Articles</h1>
                        <div className='flex justify-between items-center flex-wrap w-full gap-20'>
                            {
                               
                            currentPosts.map((article,i) => (
                                <Card image={article.coverPhoto.url} title={article.title} key={i}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
