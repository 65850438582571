import {useEffect, useState} from 'react';
import {BiPhoneCall,BiRightArrowAlt,BiMenuAltLeft} from 'react-icons/bi'
import {AiFillFacebook,AiOutlineInstagram} from 'react-icons/ai'
import {TbBrandTelegram} from 'react-icons/tb'
const Header = ({home}) => {
    const [active, setActive] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const headerItems = [
        {
            id: 1,
            name: 'Home',
            link: '/',
        },
        {
            id: 2,
            name: 'About Us',
            link: '/about-us',
        },
        {
            id: 3,
            name: 'What We Do',
            link: '/what-we-do',
        },
        {
            id: 4,
            name: 'Blog',
            link: '/blog',
        },
        {
            id: 5,
            name: 'Contact Us',
            link: '/contact-us',
        },
    ];

    const menuClickHandler = () => {
        setActive(true);
    };
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        console.log(home,"home")
    },[])

    return (
        <header className={`overflow-x-hidden sm:overflow-x-visible w-full ${home ? 'bg-[#006E52]' : 'bg-[#F3EDCD]'}  h-[10vh] text-gray-100`}>
            <div className='w-full flex justify-around items-center h-full '>
                <div className='ml-10 sm:ml-0 flex justify-center h-[60%] items-center w-[10%] sm:w-[5%] '>
                    <a href='/' className='text-2xl font-bold'>
                        <img src='/Logo.png'/>
                    </a>
                </div>
                <div className='hidden w-[40%] sm:flex justify-around'>
                    {
                        headerItems.map((item) => (
                            <a href={item.link} key={item.id} className={`cursor-pointer ${home ? 'text-white-100' : 'text-black-100'} text-[21px] font-normal`}>
                                {item.name}
                            </a>
                        ))
                    }

                </div>

                <div className="relative w-[15%] hidden  sm:flex items-center">
                    <input
                        type="text"
                        className="block w-full rounded-md border-gray-300 shadow-sm pl-3 pr-10 py-2 focus:outline-none focus:ring-primary-100 focus:border-primary-100 text-black-100 sm:text-sm"
                        placeholder="Search for Articles"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        <svg className="w-5 h-5 text-primary-200" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"
                            ></path>
                        </svg>
                    </div>
                </div>


                <div className={`z-10 h-[100vh] w-[80%] bg-primary-300 absolute top-0 transition-all duration-500 ease-in ${
                    active ? "right-0 block" : "-right-[100%] hidden"
                }`}>
                    <div className="w-full h-full flex flex-col items-start justify-start p-10 ">
                        <BiRightArrowAlt
                            onClick={() => setActive(false)}
                            color="white"
                            size="3rem"
                        ></BiRightArrowAlt>
                        {headerItems.map((e, i) => (
                            <div key={i} className="my-5 w-full">
                                <a
                                    href={e.link}
                                    className="block text-[21px] text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]"
                                >
                                    {e.name}
                                </a>
                            </div>
                        ))}
                        {/*<a href='/contactus' className='block w-full mt-2 text-lg text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]'>Contact Us</a>*/}
                        <div className='flex sm:hidden w-[100%] mt-10 justify-around items-center text-white text-base'>

                            <a href='https://www.facebook.com/profile.php?id=100087645096237'>
                                <AiFillFacebook color='white' size='1.6rem' className='cursor-pointer'/>
                            </a>
                            <a href='https://www.instagram.com/adeydigital_official/'>

                                <AiOutlineInstagram color='white' size='1.6rem' className='cursor-pointer'/>
                            </a>
                            <TbBrandTelegram color='white' size='1.6rem' className='cursor-pointer'/>

                        </div>
                    </div>
                </div>


                <div className='sm:hidden w-[20%] ml-auto'>
                    <BiMenuAltLeft color='white' size='2.5rem' className='cursor-pointer' onClick={menuClickHandler}/>
                </div>


            </div>
        </header>
    );
};

export default Header;