const ProgramCard = ({title, points}) => {
    return (
        <div className='my-10 w-full sm:w-[30%] h-auto sm:h-[37vh] bg-[#FCC531] p-10 rounded-xl flex flex-col justify-start gap-10'>
            <h1 className='text-3xl font-medium'>{title}</h1>
            <ul className='flex flex-col gap-2'>
                {points.map((point, index) => (
                    <li key={index} className='flex gap-2 font-medium'>
                        <img src='/assets/star.svg'/>
                        <p className='text-md'>{point}</p>
                    </li>
                ))}
            </ul>

        </div>
    )
}

export default ProgramCard