
import {AiOutlineArrowRight} from 'react-icons/ai'
const Card = ({image, title}) =>{
    return (
        <div className="w-full sm:w-[27%] flex flex-col justify-around gap-5">
            {/*<div className="card-body">*/}
                <img className='w-full' src={image}/>
                <h5 className="text-2xl sm:text-2xl font-semibold text-primary-100">{title}</h5>
                {/*<p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>*/}
                <div className='flex justify-start gap-1 items-center underline'>
                <a href="#" className="text-lg font-medium text-primary-100">Read More</a>
                <AiOutlineArrowRight className='mt-1 text-xl font-medium text-primary-100'/>
                </div>
            {/*</div>*/}
        </div>
    )
}

export default Card