import Card from '../components/WhatWeDo/Card.jsx'
import ProgramCard from "../components/WhatWeDo/ProgramCard";
import Header from "../components/Header";
const WhatWeDo = () => {
    return (
        <div className='bg-secondary-300'>
            <Header home={false}/>
            <div className="flex justify-center items-center w-full h-[30vh] bg-[url('/public/assets/about-hero.png')]">
                <h1 className='text-white-100 text-5xl font-medium'>What We Do</h1>
            </div>
            <div className='px-5 sm:px-32 py-20 flex flex-col items-center gap-10'>
                <p className='text-center text-[25px] font-medium md:font-normal md:text-[32px]'>
                    Moving with a mission to make important contributions towards<br/> extricating the country from shackled
                    poverty
                </p>
                <div className='my-10'>
                    <h1 className='text-3xl sm:text-5xl font-medium'>Strategic Principles</h1>
                </div>
                <div className='flex flex-col sm:flex-row justify-around items-center flex-wrap'>
                    <Card title='Effectiveness'
                          desc='Our organization designs programs based on evidence and community-based needs to find solutions to problems. We strive to improve knowledge and implement intervention strategies that reflect the needs of the community.'
                          icon='/assets/effectiveness.svg'/>
                    <Card title='Value Resources'
                          desc='We maximizes impact with available resources through effective resource mobilization, purchasing quality inputs at the right price, efficient conversion of inputs to outputs and outcomes, effective conversion of outputs to outcomes'
                          icon='/assets/value.svg'/>
                    <Card title='Demand driven'
                          desc='We prioritize community aspirations and local leadership in the design, delivery, and improvement of our programs. By transferring power and accountability to the people we serve, we empower them to drive their own development.'
                          icon='/assets/demand.svg'/>
                    <Card title='Innovation'
                          desc='SID-Ethiopia is dedicated to building a comprehensive evidence base of effective interventions. As a knowledge hub, we disseminate this information to practitioners and policy makers in a compelling and accessible way. Our goal is to push policy makers and donors to adopt evidence-based approaches and drive positive change.'
                          icon='/assets/innovation.svg'/>
                    <Card title='Empowerment'
                          desc='It believes that instead of giving a fish to the needy, building their capacities and capabilities will have a lasting positive change through a right based approach. However in some occasion we also believe to provide in handouts'
                          icon='/assets/empowerment.svg'/>
                    <Card title='Partnership'
                          desc='SID-Ethiopia values collaboration and upholds high standards of governance. Our policies and guidelines include safeguarding, HR management, finance, and procurement. We prioritize beneficiaries rights and dignity in all operations, and staff members are signatories to our safeguarding policy.'
                          icon='/assets/partnership.svg'/>

                </div>
                <div className='mt-16 mb-10'>
                    <h1 className='text-3xl sm:text-5xl font-medium text-center'>Our Program Focused Areas</h1>
                </div>
                <div className='flex flex-col sm:flex-row justify-around items-center flex-wrap'>
                    <ProgramCard title='Youth-empowerment'
                                 points={['youth leadership','Promoting Healthy youth behavior','drug addiction','promotion of volunteerism']}
                       />
                     <ProgramCard title='Resilient livelihood'
                                 points={['Family strengthening program','Urban agriculture development']}
                       />
                    <ProgramCard title='Human Right and democracy'
                                 points={['Human right advocacy','Voter and civic education']}
                       />
                    <ProgramCard title='Education'
                                 points={['reading culture development','Accessing the inaccessible children for education (like street children)']}
                       />
                    <ProgramCard title='Environmental protection'
                                 points={['solid waste management','green area development','afforestation']}
                       />
                    <ProgramCard title='Center, Community based rehabilitation'
                        points={['Empowerment and reintegration of street children, mothers and youths ','Supporting people with physical disability and mentally ill people']}
                       />



                </div>
            </div>

        </div>
    )
}

export default WhatWeDo